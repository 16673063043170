import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InterfaceUI {
  isMobileMenu: boolean
}

const initialState: InterfaceUI = {
  isMobileMenu: false
};

export const slice = createSlice({
  name: 'ui/mobileMenu',
  initialState,
  reducers: {
    toggleMobileMenu: (state: InterfaceUI, action: PayloadAction<boolean>): void => {
      state.isMobileMenu = action.payload;
    },
  },
});

export const { toggleMobileMenu } = slice.actions;
export default slice.reducer;
